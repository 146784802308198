import React, { useEffect, useState } from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import { KfButton, KfAccordion, KfAccordionItem, isMobile, debounce } from '@klickinc/kf-react-components';
import GTM from '@src/utils/GTM.js';
import Image from '@components/utility/Image';
import Cta from '@components/content/Cta';
import Hero from '@components/content/hero/Hero';
import Loadable from '@loadable/component';

//styles
import './styles.scss';

const InViewport = Loadable(() => import('@components/utility/InViewport'));

const LongTermOutcomes = () => {
	const [mobile, setMobile] = useState(false);
	const mobileHandler = () => {
		setMobile(isMobile());
	};

	const closeAccordion = (id) => {
		const el = document.querySelector(`#${id}`);
		if (el) {
			const trigger = el.querySelector('.kf-accordion__trigger button');
			if (trigger) trigger.click();
		}
	};

	const handleToggle = (e) => {
		const trigger = e.target;
		const expanded = trigger.getAttribute('aria-expanded') === 'true';
		const parent = trigger.closest('.kf-accordion');

		if (parent) {
			const id = parent.id.replace('acc-', '').replace('-1', '').replace('-2', '').replace('-', ' ');
			const label = `See ${id} summary`;
			GTM.accordionToggle(expanded, label);
		}

		if (expanded) {
			trigger.scrollIntoView();
		}
	};

	useEffect(() => {
		const mobileCheck = debounce(mobileHandler, 18);
		mobileCheck();
		if (typeof window !== 'undefined') {
			document.addEventListener('resize', mobileCheck);
			const triggers = document.querySelectorAll('.kf-accordion__trigger button');
			triggers.forEach(trigger => {
				trigger.addEventListener('click', (e) => handleToggle(e, trigger));
			});
		}
		return () => {
			document.removeEventListener('resize', mobileCheck);
			const triggers = document.querySelectorAll('.kf-accordion__trigger button');
			triggers.forEach(trigger => {
				trigger.removeEventListener('click', (e) => handleToggle(e, trigger));
			});
		};
    },[])

	return (
		<Layout>
			<Seo title='Efficacy: Long-Term Outcomes| EMFLAZA® (deflazacort)' description='View study results on the long-term outcomes of EMFLAZA® (deflazacort) vs prednisone in treating DMD. View full Prescribing Information & Important Safety Information.' />
			<div className="md:container 2xl:mx-auto page--long-term-outcomes xl:pr-48">
				<Hero heroImage='hero-2-3.png' heroImageMobile='hero-2-3-mobile.png' breadcrumbs={['Efficacy', 'Long-term outcomes']} title={<span>More of what matters, year after year<sup>10,11</sup></span>} />

				<div className="max-w-4xl px-8 text-emflaza-blue-600 md:px-0">
					{/* SECTION 1 START */}
					<div className='mt-12 md:my-20'>
						<h2 className='max-w-md text-2xl font-black uppercase text-emflaza-blue-600 md:text-4xl'>
							3 Key Milestones.<br /> A 10-Year <br />Follow-up Study.
							<sup className='superscript'>10</sup>
						</h2>

						<h3 className='my-10 text-lg font-bold leading-5 text-center uppercase text-emflaza-blue-400 md:text-2xl'>
							Deflazacort demonstrated differences <br/><span className="lowercase">vs </span> prednisone over time
							<sup className='font-medium text-xxs md:text-xs'>10</sup>
						</h3>

						<InViewport delay={500} disable={mobile} addedClass='inViewport--image-split'>
							<div className='relative top-[-20px] md:left-[-17px] md:ml-16'>
								<Image imageName='page-2-3-image-1-1.png' alt="Ability to stand (on the back position) 2.1 years longer"></Image>
								<Image imageName='page-2-3-image-1-2.png' alt="Ambulation 2.7 years longer"></Image>
								<Image imageName='page-2-3-image-1-3.png' alt="Hand-to-mouth function (with retained hand function) 2.7 years longer"></Image>
							</div>
						</InViewport>

						<div className='flex flex-wrap mb-10 md:space-x-10'>
							<div className='xl:my-5'>
								<Image imageName='page-2-3-image-2.png' addedClass='max-w-[84px]' alt="2018 Study"></Image>
							</div>
							<div className='flex-1 min-w-[300px]'>
								<p className='my-5'>
									A prospective natural history observational cohort study with 440 patients
									ages 2 to 28 years, which evaluated the long-term effects of corticosteroids in DMD.
									Patients were followed up for 10 years. Patients were stratified by duration of
									corticosteroid treatment (&#x3c;1 month or ≥12 months).
									Milestones for 9 groups of the Davis Duchenne Functional
									assessments were recorded and analyzed using Kaplan-Meier to measure
									disease progression.<sup>10</sup>
								</p>
								<KfAccordion addedId='acc-2018-study'>
									<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -" addedId="kf-accordion__acc-2018-study" addedClass='accordion-1'>
										<div className='acc-inner-content'>
											<h3 className='my-5 font-bold'>McDonald 2018 Study Summary</h3>
											<h4 className='font-bold'>Long-term effects of glucocorticoids on function, quality of life, and survival in patients with Duchenne muscular dystrophy: a prospective cohort study</h4>
											<p>
												A prospective natural history observational cohort study with 440 patients ages 2 to 28 years,
												which evaluated the long-term effects of glucocorticoids in DMD. Patients were followed up for 10 years.
												Patients were stratified by duration of glucocorticoid treatment (&#x3c;1 month or ≥12 months). Milestones
												for 9 groups of the Davis Duchenne Functional assessments were recorded and analyzed using
												Kaplan-Meier to measure disease progression.</p>
												<p>The study also compared patients treated with prednisone
												or prednisolone (PRED) versus those treated with deflazacort (DFZ) for age at loss of milestones.
												Patients with cumulative glucocorticoid treatment duration of 1 year or longer had consistently delayed
												occurrence of ambulatory disease progression milestones by periods of 2.1 to 4.4 years compared with
												glucocorticoid-naive patients or those treated for less than 1 month (all log-rank, apart from time
												to climb four stairs). Glucocorticoid treatment for 1 year or longer was associated with significantly
												delayed loss of upper limb milestones by
												2.8 to 8.0 years compared with treatment for less than 1 month. DFZ was associated with increased
												median age at loss of three milestones (age at: loss of ability to stand from supine,
												loss of ambulation, loss of hand-to-mouth function with retained hand function) by 2.1 to 2.7
												years in comparison with PRED (log-rank). The mean age at loss of the earliest milestones
												typically reached (ie, supine to stand ≥5 seconds and supine to stand ≥10 seconds) showed
												trends in delayed loss of function favoring deflazacort, but differences between agents were not significant.
												Insufficient numbers of milestone transitions were observed to allow a comparison between glucocorticoid
												regimens for loss of distal hand function. The most common side effects of glucocorticoid
												treatment were weight gain, Cushingoid features, behavior changes, growth delay, fractures,
												cataracts, and skin changes.
											</p>

											<h4 className='mt-4 font-bold'>Study Limitations</h4>
											<p>Based upon the study design and enrollment, the following limitations should be considered:</p>
											<ul className='bulleted-list'>
												<li>Steroid use was not randomized as might be in a controlled clinical trial</li>
												<li>Comparisons between DFZ and PRED are not included in the approved Prescribing Information for DFZ, as PRED is not an approved treatment for DMD</li>
												<li>The analyses included a comparison of multiple glucocorticoid agents and regimens (such as daily vs intermittent dosing)</li>
												<li>An in-depth analysis of specific doses or schedules was not feasible in a long-term, prospective, observational, natural history study</li>
												<li>DFZ and PRED utilized in this study were from various manufacturers in and outside the United States</li>
												<li>Potential differences were noted in socioeconomic status of patients and caregivers—DFZ was not commercially available in the United States at the time of the study</li>
												<li>DFZ may have been used more frequently in a daily dosing regimen</li>
												<li>The daily doses of DFZ used in the population were closer to that of the benchmarked FDA-approved labeled dose of 0.90 mg/kg/day</li>
											</ul>


											<h4 className='mt-4 font-bold'>Financial Disclosures of Study Sponsors</h4>
											<ul>
												<li className='mx-5'>
													This study was supported by US Department of Education/NIDRR (#H133B031118, #H133B090001);
													US Department of Defense (#W81XWH-12-1-0417); National Institutes of Health/NIAMS (#R01AR061875);
													and Parent Project Muscular Dystrophy.
												</li>
												<li className='pt-5 mx-5'>
													McDonald CM, Henricson EK, Abresch RT, et al; CINRG Investigators.
													Long-term effects of glucocorticoids on function, quality of life,
													and survival in patients with Duchenne muscular dystrophy:
													a prospective cohort study. <i>Lancet</i>. 2018;391(10119):451-461.
												</li>
											</ul>
											<KfButton clickFn={() => closeAccordion('acc-2018-study')} addedClass='text-emflaza-orange-300 underline my-5'>Close Study Summary -</KfButton>
										</div>
									</KfAccordionItem>
								</KfAccordion>
							</div>
						</div>

						<div className='relative xl:max-h-[15.75rem]'>
							<div className='section-bg-1'>
								<Image objectFit='contain' imageName='page-2-3-image-4.png' imageNameMobile='page-2-3-image-4-mobile.png' alt=""></Image>
							</div>
							<div className='absolute top-7 md:-top-4 xl:top-3 right-[10%] xl:right-[5.6rem] pl-8 xl:pl-0 flex flex-col md:flex-row justify-center items-center md:space-x-10'>
								<div className='max-w-[100px] md:max-w-none md:min-w-[200px]  text-center pr-8 md:pr-0'>
									<Image imageName='page-2-3-image-3.png' alt=""></Image>
								</div>
								<div className='inline-block w-[150px] h-0.5 md:w-0.5 md:h-28 xl:h-36 bg-black'></div>
								<h3 className='md:my-10 text-xl font-bold text-emflaza-blue-400 md:text-2xl text-center md:text-left max-w-[23.438rem] leading-6'>
									Deflazacort can help maintain muscle function, which may prolong ambulation
									<sup className='font-medium text-xxs md:text-xs'>10</sup>
								</h3>
							</div>
						</div>
					</div>
					{/* SECTION 1 END */}

					{/* SECTION 2 START */}
					<div className='relative section-2'>
						<div className="absolute section-bg-2 -top-11 xl:-top-40 -left-12 md:-left-20 2xl:-left-1/3 2.5xl:left-[-40%] 2.5xl:w-full 3xl:w-auto 3xl:-left-2/4 z-negative">
							<Image imageName='page-2-3-image-5.png' imageNameMobile='page-2-3-image-5-mobile.png' alt="Gaming like a 12-year-old"></Image>
						</div>
						<div className='flex flex-col space-y-32 mb-14 md:mt-32 md:gap-16 2xl:gap-56 3xl:gap-80'>
							<h2 className='text-xl pt-6 md:text-4xl font-black md:pt-0 lg:pt-20 xl:pt-0 pb-6 max-w-[150px] md:max-w-[330px] uppercase text-emflaza-white-100 leading-none md:transform md:-translate-y-7'>
								Acting with A mind toward lung function
								<sup className='superscript'>2</sup>
							</h2>
							<span className='patient-image-alt self-center max-w-[75px] ml-36 md:ml-0 2xl:my-32 3xl:my-0 3xl:mb-20 3xl:mt-72'>Not an actual patient.</span>
						</div>
					</div>
					{/* SECTION 2 END */}

					{/* SECTION 3 START */}
					<div>

						<div className='flex flex-col items-center justify-end md:flex-row gap-14'>
							<h2 className='flex-1 text-xl font-black uppercase text-emflaza-blue-600 leading-none md:text-4xl self-end md:max-w-[380px]'>
								Deflazacort Preserved Pulmonary Function Better Than Prednisone
								<sup className='superscript'>2</sup>
							</h2>
							{/* md:ml-16 */}
							<div className='flex-1 max-w-[250px] md:max-w-[335px]'>
								<InViewport delay={500} disable={mobile} addedClass='inViewport--image'>
									<Image imageName='page-2-3-image-6.png' imageNameMobile='page-2-3-image-6-mobile.png' alt="9.24% (CI: 3.06-15.41) greater mean forced vital capacity vs prednisone"></Image>
								</InViewport>
							</div>
						</div>

						<div className='flex flex-wrap my-10 md:gap-10'>
							<div className='xl:my-5'>
								<Image imageName='page-2-3-image-7.png' addedClass='max-w-[84px]' alt="2020 Study"></Image>
							</div>
							<div className='flex-1 min-w-[300px]'>
								<p className='my-5'>
									Results from a real-world, single-center, retrospective cohort analysis of
									435 boys with DMD who attended the Comprehensive Neuromuscular
									Center at Cincinnati Children’s Hospital Medical Center (CCHMC)
									from 2004 to March 2017.<sup>2</sup>
								</p>
								<KfAccordion addedId='acc-2020-study'>
									<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -" addedId="kf-accordion__acc-2020-study" addedClass='accordion-2'>
										<div className='acc-inner-content'>
											<h3 className='my-5 font-bold'>Marden 2020 Study Summary</h3>
											<h4 className='font-bold'>
												Real-world outcomes of long-term prednisone and deflazacort use
												in patients with Duchenne muscular dystrophy: experience at a single,
												large care center
											</h4>
											<p>
												A retrospective study assessing outcomes among patients with DMD
												receiving deflazacort or prednisone in real-world practice.
												435 DMD patients, aged 4 years and older, who attended the CCHMC
												from 2004 to March 2017 were studied retrospectively using time-to-event
												and regression analyses. Loss of ambulation was identified as functional
												mobility scale (FMS) score &#62;4, which is indicative of patients’
												full-time use of a wheelchair for mobility in DMD.
												Age at onset of scoliosis was defined as the first recorded clinical
												diagnosis of scoliosis in the patient’s medical record.
												Age at first occurrence of FMS &#62;4 and first diagnosis of scoliosis
												were studied in time-to-event analyses, with age as the time variable,
												and censoring at the end of data availability. Kaplan-Meier curves and
												log-rank tests were calculated to compare these outcomes between steroid
												initiation groups. Cox proportional hazards analyses were used to estimate
												the association between steroid type and age at event while adjusting
												for age at steroid initiation, the calendar year during which the patient
												had initiated steroid use, and whether the patient had initiated steroids
												prior to or after their first clinic visit at CCHMC. The adjusted analyses
												thus accounted for differences in the duration of steroid exposure, potential
												temporal trends that might impact outcomes across groups, and potential
												differences in care received prior to CCHMC. Sensitivity analyses were
												conducted distinguishing between patients who received daily versus other
												regimens of prednisone and, separately, distinguishing those who maintained
												prednisone versus those who switched to deflazacort after initiation.
												<br/> Among n = 600 total boys in the CCHMC database, n = 559 had their
												date of steroid initiation recorded and n = 500 had a steroid
												type recorded at some point during their follow-up history at CCHMC.
												A further n = 435 of these patients had at least one assessment
												of FMS or an assessment for scoliosis. The overall average follow-up
												included in the time-to-event analyses was 11.2 years in the FMS
												analyses and 11.9 years in the scoliosis analyses. The follow-up
												time was similar across prednisone- and deflazacort-initiated patients
												(&#x3c;6 months difference in mean follow-up time).
												At first clinic visit at CCHMC with functional data recorded,
												deflazacort-initiated patients were on average younger,
												shorter and weighed less, compared with prednisone-initiated patients.
												The deflazacort-initiated group was also younger on average at steroid
												initiation compared with the prednisone-initiated group
												(5.7 vs 6.4 years, respectively). Across measures of ambulatory,
												pulmonary and cardiac function at the first clinic visit at CCHMC,
												deflazacort-treated patients tended to have better or similar function
												compared with prednisone-treated patients.
											</p>

											<h4 className='mt-4 font-bold'>Study Limitations</h4>
											<p>Steroid use was not randomized as in a clinical trial. Therefore:</p>
											<ul className='bulleted-list'>
												<li>Differences in outcomes between steroid groups could be confounded by factors that differ between these groups</li>
												<li>It could also be hypothesized that differences in care received prior to CCHMC, including potential differences in steroid dosing or supportive care, could have impacted outcomes</li>
												<li>A higher proportion of patients initiating prednisone did so prior to CCHMC compared with those initiating deflazacort it is possible that unobserved differences are present</li>
												<li>Cost has been a factor for most families at CCHMC who opt for prednisone instead of out-of-pocket, imported deflazacort</li>
												<li>The impact of different dosing regimens is a plausible contributor to the differences in outcomes observed in the present study between patients receiving prednisone and deflazacort</li>
												<li>Ambulatory test results were not available from patients who were unable or unwilling to complete the assessments</li>
											</ul>
											<br/>

											<p>
												This study was facilitated by cTAP with funding from PTC.
												cTAP is a pre-competitive coalition of academic clinicians,
												drug developers and patient foundations formed in 2015 to overcome
												challenges affecting clinical trials in DMD. cTAP has received sponsorship
												from Astellas Pharma (Mitobridge), BioMarin, Biophytis, Bristol-Myers Squibb,
												Catabasis, FibroGen, Inc., Italfarmaco SpA, Marathon Pharmaceuticals,
												Pfizer, Inc., PTC Therapeutics, Roche, Sarepta Therapeutics, Shire plc,
												Solid Biosciences, Summit Plc., Wave Life Sciences, Parent Project Muscular
												Dystrophy, Charley’s Fund and CureDuchenne, a founding patient advocacy partner
												and provider of initial seed funding to cTAP.
											</p>

											<p className='pt-5'>
												Marden JR, Freimark J, Yao Z, Signorovitch J, Tian C, Wong BL. Real-world outcomes of long-term prednisone and deflazacort use in patients with Duchenne muscular dystrophy: experience at a single, large care center. <i>J Comp Eff Res</i>. 2020;9(3):177-189.
											</p>
											<KfButton clickFn={() => closeAccordion('acc-2020-study')} addedClass='text-emflaza-orange-300 underline my-5'>Close Study Summary -</KfButton>
										</div>
									</KfAccordionItem>
								</KfAccordion>
							</div>
						</div>

						<div>
							<h4 className='mt-4 font-bold'>Study Limitations</h4>
							<p>
								Comparisons between deflazacort and prednisone are not included
								in the Prescribing Information for deflazacort because prednisone
								is not an approved treatment for DMD.
							</p>
						</div>
					</div>
					{/* SECTION 3 END */}

					<div className="flex flex-wrap mb-8 space-y-5 md:space-y-0 md:space-x-5 mt-14">
						<Cta ctaUrl='/getting-started/switch-considerations' iconUrl={'cta-s-icon.png'} ctaText='Switching Corticosteroids' addedClass='cta flex-1'/>
						<Cta ctaUrl='/tolerability/safety-profile' iconUrl={'cta-icon-shield.png'} ctaText='Safety Profile' addedClass='cta flex-1' iconAddedClass='max-w-[63px]'/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default LongTermOutcomes;
